import { FC } from 'react';

import { ChosenIcon, ChosenIconProps } from '@common/elements/ChosenIcon';

import styles from './NavArrow.module.scss';

interface Props extends ChosenIconProps {
    onClick: () => void;
    className?: string;
}

export const NavArrow: FC<Props> = ({ onClick, className, icon }) => (
    <span className={`${styles.NavArrow} ${className}`} onClick={() => onClick()}>
        <ChosenIcon icon={icon} />
    </span>
);
