import { FC, ReactElement } from 'react';

import { PlatformID } from '@common/clients/api';
import { useContextData } from '@common/useContextData';

export interface Props {
    include?: PlatformID[];
    exclude?: PlatformID[];
    children: ReactElement;
}

export const PlatformToggle: FC<Props> = ({ include, exclude, children }) => {
    const contextData = useContextData();
    const platform = contextData?.context.platform.id as PlatformID;

    const isPlatformIncluded = !include || include.includes(platform);
    const isPlatformExcluded = exclude?.includes(platform);

    return isPlatformIncluded && !isPlatformExcluded ? children : null;
};
