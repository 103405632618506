import { FC, useState } from 'react';
import Image, { ImageLoaderProps, ImageProps } from 'next/image';

import { Platform } from '@common/defaults';
import { WebpWidth, webpWidths } from '@common/types/WebpWidth';
import { useContextData } from '@common/useContextData';

export interface Props extends Partial<ImageProps> {
    src?: string;
    defaultSize: WebpWidth;
    ratio?: number;
    fallbackSrc?: string;
}

const imageLoader = (props: ImageLoaderProps) =>
    props.src.replace('{WebpWidth}', `${getRightSize(props.width)}`);

const getRightSize = (width: number): number => {
    const result = webpWidths.reduce((prev, curr) => {
        return Math.abs(curr - width) < Math.abs(prev - width) ? curr : prev;
    });
    return result;
};

const getDefaultFallbackLoader = (platform: Platform) => () => {
    return `${platform.assetsBaseHref}images/${platform.id}/placeholder.jpg`;
};

export const WebpImage: FC<Props> = ({ defaultSize, ratio, alt, src, fallbackSrc, ...props }) => {
    const {
        context: { platform },
    } = useContextData();

    const [useFallback, setUseFallback] = useState<boolean>(!src);
    const width = props.fill === true ? undefined : defaultSize;
    const height = width && ratio ? width / ratio : undefined;
    const fallbackLoader = fallbackSrc ? () => fallbackSrc : getDefaultFallbackLoader(platform);

    return (
        <Image
            alt={alt || ''}
            loader={useFallback ? fallbackLoader : imageLoader}
            src={src && !useFallback ? src : fallbackLoader()}
            width={width}
            height={props.fill === true ? undefined : height}
            onError={() => {
                setUseFallback(true);
            }}
            style={{
                objectFit: 'cover',
                objectPosition: 'top center',
                aspectRatio: width && ratio ? `${ratio * 1000} / 1000` : undefined,
            }}
            {...props}
        />
    );
};
