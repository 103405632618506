import { FC, ReactElement, useCallback, useState } from 'react';

import { DisplayType } from '../NewsList/NewsList';

import styles from './IconTabs.module.scss';

export interface Tab {
    type: DisplayType;
    icon: ReactElement;
}

interface Props {
    tabs: Tab[];
    onIconTabClick: (type: string) => void;
}

export const IconTabs: FC<Props> = ({ onIconTabClick, tabs }) => {
    // Use first item in array to set default state
    const [activeClass, setActiveClass] = useState<string>(tabs[0].type);

    // Set class based on what tab is clicked
    const onTabClick = useCallback(
        (type: string): void => {
            if (tabs && onIconTabClick) {
                activeClass !== type && setActiveClass(type);
                onIconTabClick(type);
            }
        },
        [activeClass, onIconTabClick, tabs],
    );

    return (
        <ul className={styles.IconTabs}>
            {tabs.map((tab, key) => (
                <li
                    className={activeClass === tab.type ? styles['selected'] : ''}
                    onClick={() => onTabClick(tab.type)}
                    key={key}
                >
                    {tab.icon}
                </li>
            ))}
        </ul>
    );
};
