import { FC, ReactElement, ReactNode, useRef, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { ModuleScheduleItem, News, Pagination, PlatformID, Tag } from '@common/clients/api';
import { Icon } from '@common/elements/Icon';
import { Link } from '@common/elements/Link';
import { useContextData } from '@common/useContextData';
import { DateTimeUtil } from '@common/utils/DateTimeUtil';
import { IconTabs, Tab } from '@web/molecules/IconTabs';
import { NewsListItem } from '@web/molecules/NewsList/NewsListItem';
import { PromoItem } from '@web/molecules/NewsList/PromoItem/PromoItem';

import { CarrouselWrapper } from './CarrouselWrapper';

import styles from './NewsList.module.scss';

export enum DisplayType {
    compact = 'compact',
    list = 'list',
    blocks = 'blocks',
    carrousel = 'carrousel',
}

interface ReadMoreButtonProps {
    icon: JSX.Element;
    onClick: () => void;
    className?: string;
    label: string;
}

export const ReadMoreButton: FC<ReadMoreButtonProps> = ({ onClick, className, label, icon }) => (
    <button className={className} onClick={onClick}>
        {label} {icon}
    </button>
);

export interface Props {
    items: News[] | undefined;
    promoItems?: ModuleScheduleItem[] | undefined;
    pagination?: Pagination;
    showIconTabs?: boolean;
    tag?: Tag;
    title?: string | JSX.Element;
    moreArticlesLink?: string;
    skipDivider?: boolean;
    displayType?: DisplayType;
    displayTypeSwitchOptions?: DisplayType[];
    onReadMoreClick?: (pagination: Pagination | undefined) => void;
    isHighlighted?: boolean;
}

export const NewsList: FC<Props> = (props) => {
    const { context } = useContextData();
    const __newsList = useTranslation('newslist').t;
    const [displayType, setDisplayType] = useState<DisplayType>(
        props.displayType ?? props.displayTypeSwitchOptions?.[0] ?? DisplayType.list,
    );

    const firstElementRef = useRef<HTMLLIElement>(null);

    // Handle click action for slider
    const onReadMoreClick = (pagination: Pagination | undefined): void => {
        if (props.onReadMoreClick) {
            props.onReadMoreClick(pagination);
        }
    };

    const onIconTabClick = async (type: string): Promise<void> => {
        setDisplayType(type as DisplayType);
    };

    const items: ReactElement[] = [];
    let lastDivider: string;

    let iconTabs: Tab[] = [
        {
            type: DisplayType.list,
            icon: <Icon.listBig />,
        },
        {
            type: DisplayType.compact,
            icon: <Icon.listSmall />,
        },
    ];

    //Only show the recieved items in props
    if (Array.isArray(props.displayTypeSwitchOptions)) {
        iconTabs = [];

        //Add the selectedType, if it is not passed to the displayTypes
        if (
            !props.displayTypeSwitchOptions?.find((_displayType) => {
                return displayType === _displayType;
            })
        ) {
            props.displayTypeSwitchOptions?.unshift(displayType);
        }

        props.displayTypeSwitchOptions?.forEach((displayType) => {
            let _icon: ReactElement = <></>;
            switch (displayType) {
                case DisplayType.list:
                    _icon = <Icon.listBig />;
                    break;
                case DisplayType.compact:
                    _icon = <Icon.listSmall />;
                    break;
                case DisplayType.blocks:
                    _icon = <Icon.blocks />;
                    break;
                case DisplayType.carrousel:
                    _icon = <Icon.carrousel />;
                    break;
            }
            iconTabs.push({
                type: displayType,
                icon: _icon,
            });
        });
    }

    const topItems = props.promoItems?.filter((promo) => !promo?.frequency && promo);

    // Add all inserts for newslist - PB-3546
    props.items?.forEach((item: News, key: number) => {
        let divider: ReactNode = false;
        const dividerDate: string =
            displayType === DisplayType.compact
                ? DateTimeUtil.format(new Date(item.newsDate), DateTimeUtil.formats.DATE_MEDIUM)
                : '';

        if (props.skipDivider) {
            // do nothing
        } else if (key === 0) {
            divider = (
                <li className={styles.divider + ' ' + styles.title} key={'divider-' + key}>
                    {props.title ? (
                        typeof props.title === 'string' ? (
                            <h2>{props.title}</h2>
                        ) : (
                            <h3>{props.title}</h3>
                        )
                    ) : (
                        <h3>
                            {/**
                             *
                             * NewsList.module.scss - ilne 174 --> &:global(.is-highlighted) {}
                             * - Use h2 for above selector
                             * ? Unsure when props.title or props.tag.title ?
                             *
                             **/}
                            {__newsList`the-latest`} {props.tag?.title} {__newsList`news`}
                        </h3>
                    )}

                    {props.moreArticlesLink && (
                        <Link href={props.moreArticlesLink}>
                            {__newsList`show-more`}
                            <Icon.right />
                        </Link>
                    )}

                    {props.showIconTabs && <IconTabs tabs={iconTabs} onIconTabClick={onIconTabClick} />}
                </li>
            );
        } else if (dividerDate != lastDivider) {
            divider = (
                <li className={styles.divider + ' ' + styles.date} key={'divider-' + key}>
                    <h3>{dividerDate}</h3>
                </li>
            );
        }
        lastDivider = dividerDate;

        if (divider) items.push(divider);

        if (key === 0 && topItems) {
            topItems.forEach((promo: ModuleScheduleItem) => {
                items.push(<PromoItem item={promo} key={`promoItem-${key}`} displayType={displayType} />);
            });
        }

        if (props?.promoItems && key > 0) {
            props.promoItems.forEach((promo: ModuleScheduleItem) => {
                if (promo?.frequency && (key + 1) % promo.frequency === 0) {
                    items.push(<PromoItem item={promo} key={`promoItem-${key}`} displayType={displayType} />);
                }
            });
        }

        items.push(
            <NewsListItem
                item={item}
                tag={props.tag}
                key={`NewsListItem-${key}`}
                displayType={displayType}
                firstElementRef={key === 0 ? firstElementRef : undefined}
            />,
        );
    });

    if (items.length === 0) {
        // Nothing to show
        if (!topItems) return <></>;

        // Promo to show
        items.push(
            <li className={styles.divider + ' ' + styles.date} key={'divider'}>
                <h3>
                    {__newsList`the-latest`} {props.tag?.title} {__newsList`news`}
                </h3>
            </li>,
        );
        topItems.forEach((promo: ModuleScheduleItem, key: number) => {
            items.push(<PromoItem item={promo} key={`promoItem-${key}`} displayType={displayType} />);
        });
    }

    let readMore: ReactElement = <></>;
    const platformButtonIcon = context.platform.id === PlatformID.GP ? <Icon.down /> : <></>;
    const platformButtonText =
        context.platform.id === PlatformID.GP ? __newsList(`more-news`) : __newsList(`more-articles`);

    if (props.pagination?.hasNextPage) {
        readMore = (
            <button className={styles['read-more']} onClick={() => onReadMoreClick(props.pagination)}>
                {platformButtonText} {platformButtonIcon}
            </button>
        );
    }

    const newsCount = props.items?.length || 0;

    const classes = [styles.NewsList];
    classes.push(styles[`${displayType}-display-type`]);
    props?.isHighlighted && classes.push('is-highlighted inverted');

    return (
        <section className={classes.join(' ')}>
            {displayType === DisplayType.carrousel ? (
                <>
                    <CarrouselWrapper
                        defaultProps={props}
                        onReadMoreClick={onReadMoreClick}
                        isHighlighted={Boolean(props?.isHighlighted)}
                        {...{
                            items,
                            firstElementRef,
                            newsCount,
                        }}
                    />
                </>
            ) : (
                <>
                    <ul>{items}</ul>
                    {readMore}
                </>
            )}
        </section>
    );
};
