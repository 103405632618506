import { FC } from 'react';

import { Icon, IconKey, IconOption } from '@common/elements/Icon';

export interface Props {
    icon: IconKey;
}

/**
 * Component with type-hinting that renders a chosen icon based on the
 * icon key provided.
 * @example <ChosenIcon icon={Option.menu} /> renders the menu icon.
 * @param {Props} props
 * @param {IconKey} props.icon
 * @return {JSX.Element}
 */
export const ChosenIcon: FC<Props> = ({ icon }: Props): JSX.Element => {
    const ChosenOption = Icon[IconOption[icon]];
    return <ChosenOption />;
};
