import { FC, ReactElement, RefObject, useMemo } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { News, PlatformID, Tag } from '@common/clients/api';
import { Icon } from '@common/elements/Icon';
import { Link } from '@common/elements/Link';
import { WebpWidthEnum } from '@common/types/WebpWidth';
import { DateTimeUtil, Perspective } from '@common/utils/DateTimeUtil';
import { InternalLink, Route } from '@web/atoms/InternalLink';
import { TagElement } from '@web/atoms/TagElement';
import { WebpImage } from '@web/atoms/WebpImage';
import { PlatformToggle } from '@web/molecules/PlatformToggle';

import { DisplayType } from '../NewsList';

import styles from './NewsListItem.module.scss';

interface Props {
    item: News;
    displayType?: DisplayType;
    isDossier?: boolean;
    firstElementRef?: RefObject<HTMLLIElement>;
    tag?: Tag;
}

const calculateIsMini = (displayType?: DisplayType): boolean => {
    return displayType === DisplayType.compact || displayType === DisplayType.list;
};

export const NewsListItem: FC<Props> = ({ item, displayType, isDossier, firstElementRef, tag }) => {
    const __datetime = useTranslation('datetime').t;
    const date: Date = new Date(item.newsDate);

    let flag: ReactElement = (
        <span className="icon-holder">
            <span className="competition-icon default-competition" />
        </span>
    );

    if (item.mainTagSlug && displayType === DisplayType.compact) {
        flag = (
            <InternalLink className="icon-holder" route={Route.Competition} query={{ tag: item.mainTagSlug }}>
                <span className={`competition-icon ${item.mainTagSlug}-competition`} />
            </InternalLink>
        );
    }

    // Add flag and date format before title
    const titlePrefix: ReactElement = (
        <>
            {flag}
            <span className={styles.time} suppressHydrationWarning={true}>
                {DateTimeUtil.format(date, DateTimeUtil.formats.TIME_NUMERIC)}
            </span>
        </>
    );

    // Dossier radiobutton
    const radioButton: ReactElement = <span className={styles.radio} />;

    // Set path to article
    let path: string = '';
    if (item.host && item.path) {
        path = item.host + item.path;
    }

    const classes = [styles.NewsItem];
    const dataItems: { [key: string]: string } = {};
    if (displayType) classes.push(styles[displayType]);
    if (isDossier) classes.push(styles.dossier);
    if (item.category?.functionality === 'pro') classes.push(styles.pro);
    if (item.category?.functionality) classes.push(`${item.category?.functionality}-article`);

    if (item.category?.functionality === 'betting') {
        classes.push('ndm-betting-integration-tracker');
        dataItems['data-tracker-name'] = 'advertorialNewsList';
    }

    if (displayType === DisplayType.blocks || displayType === DisplayType.carrousel) {
        classes.push(styles[`block-type`]);
    }

    const isMini = useMemo(() => calculateIsMini(displayType), [displayType]);
    const imageSize = isMini ? WebpWidthEnum.WIDTH_110 : WebpWidthEnum.WIDTH_225;

    return (
        <li className={classes.join(' ')} ref={firstElementRef} {...dataItems}>
            <PlatformToggle exclude={[PlatformID.VI]}>{isDossier ? radioButton : titlePrefix}</PlatformToggle>
            <Link href={path}>
                <div className={styles['play-video']}>
                    {item.category?.newsCatMedia && !isMini && (
                        <>
                            <PlatformToggle exclude={[PlatformID.VI]}>
                                <span className="play-button">
                                    <span className="icon"></span>
                                </span>
                            </PlatformToggle>
                            <PlatformToggle include={[PlatformID.VI]}>
                                <span className={styles.time}>
                                    {DateTimeUtil.format(date, DateTimeUtil.formats.TIME_NUMERIC)}
                                </span>
                            </PlatformToggle>
                        </>
                    )}
                    <WebpImage
                        defaultSize={imageSize}
                        className={styles.image}
                        src={item.image || ''}
                        alt={''}
                        ratio={110 / 60}
                    />
                </div>
            </Link>

            {!isMini && (
                <div className={styles['tag-container']}>
                    <p>{item.category?.newsCatName}</p>
                </div>
            )}
            <div className={isDossier ? styles.content + ' content' : styles.content}>
                <Link href={path}>
                    <h4>
                        <div className={styles['title-wrapper']}>
                            <PlatformToggle exclude={[PlatformID.VI]}>
                                <TagElement item={item} displayType={displayType} tag={tag} />
                            </PlatformToggle>
                            <PlatformToggle include={[PlatformID.VI]}>
                                <>
                                    {item.category?.functionality === 'pro' && (
                                        <span className={styles.proTag}>
                                            <Icon.pro /> <TagElement item={item} />
                                        </span>
                                    )}
                                </>
                            </PlatformToggle>
                            <p className={styles['title']}>{item.newsTitle}</p>
                        </div>
                    </h4>
                </Link>
                <PlatformToggle exclude={[PlatformID.VI]}>
                    <div className={styles.meta}>
                        <span className={styles['long-date']} suppressHydrationWarning={true}>
                            {DateTimeUtil.formatDuration({
                                date,
                                __translate: __datetime,
                                perspective: Perspective.IN_AGO,
                            })}
                        </span>
                        {item.commentCount ? (
                            <Link href={path + '#comments_anchor'}>
                                <span className={styles.comments}>
                                    <Icon.comment />
                                    {item.commentCount}
                                </span>
                            </Link>
                        ) : null}
                    </div>
                </PlatformToggle>
            </div>
        </li>
    );
};
