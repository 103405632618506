import { FC, useMemo } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { Item, ModuleScheduleItem, ModuleScheduleSection } from '@common/clients/api';
import { Icon } from '@common/elements/Icon';
import { Link } from '@common/elements/Link/Link';
import { Logo } from '@common/elements/logo';
import { BookmakerLogo } from '@web/atoms/BookmakerLogo';
import { InternalLink, Route } from '@web/atoms/InternalLink';
import { TagElement } from '@web/atoms/TagElement';

import { DisplayType } from '../NewsList';

import styles from './PromoItem.module.scss';

interface Props {
    item: ModuleScheduleItem | Item;
    displayType?: DisplayType;
    hasDarkBackground?: boolean;
}

export const PromoItem: FC<Props> = ({ item, displayType, hasDarkBackground }) => {
    const __meta = useTranslation('meta').t;
    const size = useMemo(() => {
        return displayType === 'compact' ? 'small' : 'big';
    }, [displayType]);

    const url = item.url;
    const bookieUrl = item.bookmaker?.url;
    const text = (item as ModuleScheduleItem).text || (item as Item).title || '';
    const oddsDossierItem = (item as Item).tag || undefined;
    const promoItem = (item as ModuleScheduleItem).text || undefined;

    const icon = hasDarkBackground
        ? item?.bookmaker?.darkBackgroundIcon
        : item?.bookmaker?.lightBackgroundIcon;

    return (
        <li
            className={`${styles.PromoItem} ${styles[size]} promo-item ndm-betting-integration-tracker`}
            key={'promo-item_' + item.id}
            data-tracker-name={ModuleScheduleSection.TAG_NEWS_LIST}
        >
            {/* Use bookmaker url, else use item url */}
            {promoItem || oddsDossierItem ? (
                <div className={styles['logo-wrapper']}>
                    {promoItem ? (
                        <BookmakerLogo
                            className={styles['bookmaker-logo']}
                            alt={item?.bookmaker?.name || ''}
                            url={bookieUrl ?? url}
                            src={icon ?? ''}
                        />
                    ) : null}
                    {oddsDossierItem ? (
                        <Link href={url ?? bookieUrl ?? oddsDossierItem ?? ''} isSponsored>
                            <TagElement item={item ?? oddsDossierItem ?? undefined} />
                        </Link>
                    ) : null}
                </div>
            ) : null}
            {/* Double classnames used to target styling in OddsDossierIntro.tsx */}
            <div className={`${styles['content']} content`}>
                <h4>
                    <Link href={url ?? bookieUrl ?? ''} isSponsored>
                        {text}
                    </Link>
                </h4>
            </div>
            {promoItem ? (
                <div className={`${styles['betting-container']} betting-container`}>
                    <InternalLink route={Route.Page} query={{ slug: __meta('url:play-responsibly') }}>
                        <a rel="nofollow" target="_blank" className={styles['disclaimer-wrapper']}>
                            <Icon.settings />
                            <span className={`${styles['settings-text']}`}>{__meta`label.hide-betting`}</span>
                        </a>
                    </InternalLink>
                    <InternalLink route={Route.Page} query={{ slug: __meta('url:play-responsibly') }}>
                        <a rel="nofollow" target="_blank" className={`${styles['logo-betting']}`}>
                            <Logo.eighteenPlus />
                        </a>
                    </InternalLink>
                </div>
            ) : (
                <div className={`${styles['logo-wrapper']} logo-wrapper`}>
                    <BookmakerLogo
                        className={styles['bookmaker-logo']}
                        alt={item?.bookmaker?.name || ''}
                        url={bookieUrl ?? url}
                        src={icon ?? ''}
                    />
                </div>
            )}
        </li>
    );
};
